import dynamic from "next/dynamic";
const YoutubeContainer = dynamic(() => import("./YoutubeContainer"));

const YouTubeIndex = () => {
  return (
    <div className="caroYoutube">
      <YoutubeContainer />
    </div>
  );
};

export default YouTubeIndex;
