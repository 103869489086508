import React from 'react';
import AudienceCardHorizontal from './AudienceCardHorizontal';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';
import { makeStyles } from'@mui/styles';
import { IconMaker } from '../common/IconMaker';
import audiences from '../../data/audiences.json';

export default function CardsInTabs() {
  const [value, setValue] = React.useState(0);
  const [indicatorColor, setIndicatorColor] = React.useState(
    audiences[0].color
  );
  const classes = useStyle({ indicatorColor });

  const handleChange = (_, activeTab) => {
    setIndicatorColor(audiences[activeTab].color);
    setValue(activeTab);
  };

  return (
    <Box mb={6}>
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: "unset", marginBottom: "2rem" }}
        aria-label="Audience categories"
      >
        <Box mx="auto">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="standard"
            textColor="inherit"
            TabIndicatorProps={{ className: classes.tabsIndicator }}
            aria-label="Select audience category"
          >
            {audiences.map(({ audience }, index) => (
              <Tab
                key={audience}
                className="muiButton-override-textTransform"
                disableRipple={true}
                label={
                  <Box mb={2} width={128}>
                    <Typography align="center" className={classes.cardTitle}>
                      {audience}
                    </Typography>
                  </Box>
                }
                icon={
                  <IconMaker
                    className="MuiTab-wrapper-icon"
                    name={audience}
                    alt={audience}
                    width="64px"
                    height="64px"
                  />
                }
                {...{
                  id: `scrollable-force-tab-${index}`,
                  "aria-controls": `scrollable-force-tabpanel-${index}`,
                }}
              />
            ))}
          </Tabs>
        </Box>
      </AppBar>
      {audiences.map(({ audience, description }, index) => (
        <div
          key={audience}
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-force-tabpanel-${index}`}
          aria-labelledby={`scrollable-force-tab-${index}`}
        >
          {value === index && (
            <Box p={3}>
              <AudienceCardHorizontal
                audience={audience}
                description={description}
              />
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  tabsIndicator: ({ indicatorColor }) => ({
    background: indicatorColor,
    height: "4px",
    width: "100%",
  }),
  cardTitle: {
    lineHeight: "27px",
    letterSpacing: ".15px",
    color: "#001B11",
    fontSize: "18px",
    fontWeight: "600",
  },
}));
