import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';
import { makeStyles } from'@mui/styles';

const CardAnimation = dynamic(() => import('./CardAnimation'));

export default function AudienceCardVertical(props) {
  const classes = useStyle();

  return (
    <Box
      className={`audience-card ${props.audience}-card-narrow`}
      style={{ maxWidth: 522 }}
      role="article"
      aria-label="audience card"
    >
      <Box style={{ position: 'relative', top: '-25px' }}>
        <Grid container direction="column" alignItems="center" maxWidth="100vw">
          <Box style={{ height: '320px' }}>
            <CardAnimation cardName={props.audience?.toLowerCase()} height="320px" aria-label="audience-card" />
          </Box>
          <Box className={classes.textBox}>
            <Typography className={classes.title} role="heading" aria-level="2" >{props.audience}</Typography>
            <div className="underline" />
            <Typography className={classes.description} role="document" aria-label="description">
              {props.description}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles(() => ({
  title: {
    lineHeight: '27px',
    letterSpacing: '.15px',
    color: '#001B11',
    fontSize: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins',
  },
  description: {
    lineHeight: '27px',
    letterSpacing: '.15px',
    color: '#001B11',
    fontSize: '18px',
    fontWeight: '400',
  },
  textBox: {
    maxWidth: '458px',
    padding: '0 2rem 2rem',
    position: 'relative',
    top: '-22px',
  },
}));
