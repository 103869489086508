import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';
import { makeStyles } from'@mui/styles';

const CardAnimation = dynamic(() => import('./CardAnimation'));

export default function AudienceCardHorizontal(props) {
  const classes = useStyle();

  return (
    <Grid
      container
      className={`audience-card ${props.audience}-card-wide`}
      style={{ height: 363 }}
      role="group"
      aria-label="audience-card"
    >
      <Box mx={6} my={6}>
        <Grid container justifyContent="center" align="center">
          <CardAnimation cardName={props.audience?.toLowerCase()} aria-label="audience-card" />
          <Box maxWidth="458px" my={5} mx="auto">
            <Typography className={classes.description} role="article" aria-label="description">
              {props.description}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

const useStyle = makeStyles(() => ({
  description: {
    lineHeight: '27px',
    letterSpacing: '.15px',
    color: '#001B11',
    fontSize: '18px',
    fontWeight: '400',
  },
}));
