import dynamic from "next/dynamic";
const CarouselComponent = dynamic(() => import("./CarouselComponent"));

const CarouselContainer = () => {
  // hooks

  return (
    <div className="testimonials">
      <CarouselComponent />
    </div>
  );
};

export default CarouselContainer;
