import useScreenSize from '../customHooks/useScreenSize';
import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from'@mui/styles';
import audiences from '../../data/audiences.json';
import AudienceCardVertical from './AudienceCardVertical';
import CardsInTabs from './CardsInTabs';

export default function AudienceSection() {
  const screenLayoutType = useScreenSize();
  const classes = useStyle();

  return (
    <Box className={classes.container} role="region" aria-label="audience-section">
      <Container>
        <Grid container direction="column" alignItems="center">
          {screenLayoutType !== 'largeScreen' ? (
            <Grid container direction="column" spacing={4}>
              <Typography align="center" className={classes.sectionTitle}>
                Who Is TeammateMe For?
              </Typography>
              {audiences.map(({ audience, description }) => (
                <Grid item key={audience}>
                  <AudienceCardVertical
                    audience={audience}
                    description={description}
                    aria-label="audience card"
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CardsInTabs />
          )}
        </Grid>
      </Container>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F5F4F2',
    padding: '4rem 0 3rem',
  },
  sectionTitle: {
    fontSize: '28px',
    // @TODO update font weight to 600
    fontWeight: 700,
    marginBottom: '1rem',
    [theme.breakpoints.down(400)]: {
      fontSize: '6vw',
    },
  },
  cardTitle: {
    lineHeight: '27px',
    letterSpacing: '.15px',
    color: '#001B11',
    fontSize: '18px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
}));
